import React from 'react'
import Swiper from 'react-id-swiper';
import slide1 from "../../images/gallery/sclass/slide1.jpg"
import slide2 from "../../images/gallery/sclass/slide2.jpg"
import slide3 from "../../images/gallery/sclass/slide3.jpg"
import slide4 from "../../images/gallery/sclass/slide4.jpg"
import slide5 from "../../images/gallery/sclass/slide5.jpg"
import slide6 from "../../images/gallery/sclass/slide6.jpg"
import slide7 from "../../images/gallery/sclass/slide7.jpg"
import slide8 from "../../images/gallery/sclass/slide8.jpg"
import slide9 from "../../images/gallery/sclass/slide9.jpg"
class PartnersSlider extends React.Component {
    render() {
        const params = {
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
              },
              loop: true,  
          }
   
      return(
                    <Swiper {...params}>
                        <div className="slide"><img src={slide1} alt="Mercedes 350d S - Vip Cars Sarajevo/Rent a Car" /></div>
                        <div className="slide"><img src={slide2} alt="Mercedes 350d S - Vip Cars Sarajevo/Rent a Car" /></div>
                        <div className="slide"><img src={slide3} alt="Mercedes 350d S - Vip Cars Sarajevo/Rent a Car" /></div>
                        <div className="slide"><img src={slide4} alt="Mercedes 350d S - Vip Cars Sarajevo/Rent a Car" /></div>
                        <div className="slide"><img src={slide5} alt="Mercedes 350d S - Vip Cars Sarajevo/Rent a Car" /></div>
                        <div className="slide"><img src={slide6} alt="Mercedes 350d S - Vip Cars Sarajevo/Rent a Car" /></div>
                        <div className="slide"><img src={slide7} alt="Mercedes 350d S - Vip Cars Sarajevo/Rent a Car" /></div>
                        <div className="slide"><img src={slide8} alt="Mercedes 350d S - Vip Cars Sarajevo/Rent a Car" /></div>
                        <div className="slide"><img src={slide9} alt="Mercedes 350d S - Vip Cars Sarajevo/Rent a Car" /></div>
                    </Swiper>         
      )
    }
  }
   
  export default PartnersSlider
